body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.outlook-week {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd;
  padding: 5px;
}

.outlook-week > span {
  display: block;
  margin-right: 20px;
}

.outlook-week-amount {
  color: #999;
}

.outlook-week-amount-actual {
  color: #222;
  font-weight: bold;
}

.outlook-week-differential-under {
  color: green;
}

.outlook-week-differential-over {
  color: red;
}

.outlook-week-current {
  background: #ddd;
}

.outlook-week-badge {
  font-size: 12px;
}

.outlook-week-number {
  display: block;
  text-align: left;
}
